export const en = {
  lang: "en",
  messages: {
    "Create your {{appName}} account": "Create your {{appName}} account",
    "Text too small.": "Text too small.",
    "Invalid text.": "Invalid text.",
    "The emails don't match": "The emails don't match",
    "Invalid email address": "Invalid email address",
    "Email address too small": "Email address too small",
    "The passwords don't match": "The passwords don't match",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)":
      "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)",
    "Password must be at least 8 characters long.": "Password must be at least 8 characters long.",
    "File must be type .pdf, .jpeg, .jpg or .png.": "File must be type .pdf, .jpeg, .jpg or .png.",
    "File size must be less than 5MB.": "File size must be less than 5MB.",
    "Upload file": "Upload file",
    "The username must only contain alphanumeric characters.": "The username must only contain alphanumeric characters.",
    "Username must have at least 4 characters.": "Username must have at least 4 characters.",
    Username: "Username",
    Password: "Password",
    "New Password": "New Password",
    "Reset password": "Reset password",
    "Your account has been created successfully!": "Your account has been created successfully!",
    "Your email has been verified, enter the platform by clicking the button below!": "Your email has been verified, enter the platform by clicking the button below",
    "The username is already taken, please choose another.": "The username is already taken, please choose another.",
    "You will receive an email as soon as the account becomes active.":
      "You will receive an email as soon as the account becomes active.",
    SEND: "SEND",
    "Finish creating your {{appName}} account": "Finish creating your {{appName}} account",
    "Thank you for confirming your email address.": "Thank you for confirming your email address.",
    "Please define your username and your identity verification.":
      "Please define your username and your identity verification.",
    "Please define your username.": "Please define your username.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>",
    "<strong>The password must be at least 8 characters long, have 1 number, 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>The password must be at least 8 characters long, have 1 number, 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>",
    "{{appName}} account": "{{appName}} account",
    Identification: "Identification",
    "Identification document - front": "Identification document - front",
    "Identification document - backside (if not already icluded on the file above)":
      "Identification document - backside (if not already icluded on the file above)",
    Address: "Address",
    "Post code": "Post code",
    City: "City",
    "Proof of address": "Proof of address",
    "<p>Dated less than 3 months.</p>": "<p>Dated less than 3 months.</p>",
    "Registration successful!": "Registration successful!",
    "Please check your email to confirm your identity.": "Please check your email to confirm your identity.",
    '(Please check in the "Spam" box)': '(Please check in the "Spam" box)',
    "First Name": "First Name",
    "Last Name": "Last Name",
    Country: "Country",
    Currency: "Currency",
    "Expected monthly volume": "Expected monthly volume",
    "Interface page for register on <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> betting platform":
      "Interface page for register on <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> betting platform",
    "This is the page for making an account on {{appName}}, if you have already done this step then go to <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> or click 'Login' on <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.":
      "This is the page for making an account on {{appName}}, if you have already done this step then go to <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> or click 'Login' on <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.",
    "It only takes a minute": "It only takes a minute",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "We detected that you already have a {{appName}} account and we now resend an email with your details.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Please check your email to confirm your identity (Please check in the "Spam" box)',
    "Password updated successfully!": "Password updated successfully!",
    "Login at <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> and start betting!":
      "Login at <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> and start betting!",
    "This username does not match the current password reset token.":
      "This username does not match the current password reset token.",
    "Welcome to {{domainName}} betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Welcome to {{domainName}} betting platform. The most respected Asian bookmakers, where winners are welcome.",
    "Register a new account on {{name}}": "Register a new account on {{name}}",
    "id-card-name-EN": "Passport or Local ID Card number",
    CPF: "CPF",
    "Phone Number": "Phone Number",
    "Number between 5 and 15 digits, can start with '+'": "Number between 5 and 15 digits, can start with '+'",
    "Invalid Pattern": "Invalid Pattern",
    "Invalid CPF number": "Invalid CPF number",
    "You must be 18 or older": "You must be 18 or older",
    "Birthdate": "Birthdate",
    "Invalid date": "Invalid date",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.",
    "Confirm Password": "Confirm Password",
    "Confirm Email": "Confirm Email",
    "(DD-MM-YYYY)": "(DD-MM-YYYY)",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.":
      "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.",
    "You need to confirm Terms and Conditions and Privacy Policy": "You need to confirm Terms and Conditions and Privacy Policy",
    "Terms And Conditions": "Terms And Conditions",
    "Value already in use": "Value already in use"
  },
};
