import { Register, Settings } from "src/model/myTypes";
import { getNavigatorDefaultLanguage } from "src/app-constants";
import SiteSettings from "src/settings/settings-class";

export const InitialStateSettings: Settings = {
  userLang: getNavigatorDefaultLanguage(),
  allowedLanguages: SiteSettings.data.allowedLanguagesList,
  appName: "",
};

export const InitialStateRegister: Register = {
  registerStartResponse: false,
  registerStartMessage: "",
  usernameFree: false,
  isValidHash: true,
  hash: "",
  registerContinueResponse: false,
  registerContinueMessage: "",
  isValidHashPassword: true,
  passwordRedefineMessage: "",
  passwordRedefineSuccess: false,
  availableCurrencies: [],
  passwordChangeRequestSuccess: false,
  passwordChangeRequestMessage: "",
  requestDocumentsAddress: false,
  requestDocumentsIdentity: false,
  requestInformationAddress: false,
  requestInformationIdentity: false,
  autoApproveUser: false,
  phoneNumberResgistration: false,
  birthdateResgistration: false,
  cpfResgistration: false,
  forceEmailValidation: false,
  preData: {
    birthdate: '',
    country: '',
    cpf: '',
  } ,
};

export const INITIAL_STATE = {
  register: InitialStateRegister,
  settings: InitialStateSettings,
};
