import { SiteSettings } from "./settings-class";

export const settings: SiteSettings = {
  siteName: "Localhost",
  domainName: "Localhost.com",
  logoLink: "bettingbeast.com",
  siteLogin: "Localhost:3000/#/sign-in",
  paymentsApiUrl: "https://register.bettingbeast.com/api",
  email: "info@codigree.com",
  folderImgs: "bettingbeast-imgs",
  registerAskVolume: false,
  currenciesList: ["BRL", "EUR", "USD"],
  defaultLang: 'br',
  allowedLanguagesList: {en: 'gb', pt: 'br'}, // key: lang code, value: coutry code for flag
  styles: {
    headerBackgroundColor: "#01000A",
    headerTextColor: "#ffffff",
    headerBackgroundImage: "/img/bettingbeast-imgs/background-image.png"
  }
};
