import { _t } from "../store";

type changeLanguageAction = {
  type: "CHANGE_LANGUAGE";
  payload: string;
};

type changeWebsiteAction = {
  type: "CHANGE_WEBSITE";
  payload: string;
};

export type SettingsActions = changeLanguageAction | changeWebsiteAction;

export function changeLanguage(language: string) {
  _t.setNewLanguage(language);
  return {
    type: "CHANGE_LANGUAGE",
    payload: language,
  };
}

export function changeWebsite(name: string) {
  return {
    type: "CHANGE_WEBSITE",
    payload: name,
  };
}
