import { Options } from "./model/myTypes";
import Settings from "src/settings/settings-class";

export const LANGUAGES: Options = Settings.data.allowedLanguagesList;

export function isValidLanguage(lang: string): boolean {
  return LANGUAGES.hasOwnProperty(lang);
}

export function getNavigatorDefaultLanguage(): string {
  let defaultLangCode = Settings.data.defaultLang;
  const allowedLangCodes = Object.keys(LANGUAGES);
  let navigatorLang = window.navigator.language;
  if (typeof navigatorLang === "string" && navigatorLang.length >= 2) {
    if (navigatorLang.indexOf("-") !== -1) {
      navigatorLang = navigatorLang.split("-")[0];
    }
    if (allowedLangCodes.includes(navigatorLang)) {
      defaultLangCode = navigatorLang;
    }
  }
  return defaultLangCode;
}

export const VOLUME: Options = {
  "0 - 99,999": "0 - 99,999",
  "100,000 - 499,999": "100,000 - 499,999",
  "1,000,000 - 4,999,999": "1,000,000 - 4,999,999",
  "5,000,000+": "5,000,000+",
};
