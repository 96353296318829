import { Options } from "src/model/myTypes";

export type SiteSettings = {
  siteName: string;
  domainName: string;
  logoLink: string;
  siteLogin: string;
  paymentsApiUrl: string;
  email: string;
  folderImgs: string;
  registerAskVolume: boolean;
  currenciesList: string[]; // iso 3 currency code list
  defaultLang: string;
  allowedLanguagesList: Options; // key: lang code, value: coutry code for flag
  styles: {
    headerBackgroundColor: string;
    headerBackgroundImage?: string;
    headerTextColor: string;
  };
};

type SiteSettingsType = SiteSettings & {
  logoLinkUrl: string;
  siteLoginUrl: string;
}

class SettingsClass {
  settings: SiteSettings;
  logoLinkUrl: string;
  siteLoginUrl: string;

  constructor() {
    let urlPrefix = "https://";
    try {
      const domain = this.getDomainFromUrl();
      this.settings = require(`./${domain}.ts`).settings;
    } catch (error) {
      this.settings = require("./localhost.ts").settings;
      urlPrefix = "http://";
    }

    this.logoLinkUrl = !!this.settings.logoLink ? urlPrefix + this.settings.logoLink : "";
    this.siteLoginUrl = !!this.settings.siteLogin ? urlPrefix + this.settings.siteLogin : "";
  }

  private getDomainFromUrl(): string {
    let domain = "";
    // get part of the name right after "register." in example "register.punterplay.com"
    domain = window.location.hostname.split(".")[1]; // would be "punterplay" in above example
    // }
    return domain;
  }

  getData(): SiteSettingsType {
    return { 
      ...this.settings, 
      logoLinkUrl: this.logoLinkUrl, 
      siteLoginUrl: this.siteLoginUrl
    };
  }
}

export default class Settings {
  public static data: SiteSettingsType = new SettingsClass().getData();
}
