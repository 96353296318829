import React, { Component } from "react";
import styles from "./footer.module.css";
import { _t } from "src/redux/store";
import renderHTML from "html-react-parser";
import Settings from "src/settings/settings-class";

class Footer extends Component {
  private siteName = Settings.data.siteName;
  private siteLogin = Settings.data.siteLogin;
  private siteLoginUrl = Settings.data.siteLoginUrl;
  private contactEmail = Settings.data.email;
  private logoLink = Settings.data.logoLink;
  private logoLinkUrl = Settings.data.logoLinkUrl;

  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div className={styles.container}>
        <p>
          &copy; {this.siteName} {currentYear}
        </p>
        <p>{this.contactEmail}</p>
        <p className={styles.link}>
          {this.logoLinkUrl.length > 0 ? (
            renderHTML(
              _t.translate(
                "This is the page for making an account on {{appName}}, if you have already done this step then go to <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> or click 'Login' on <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.",
                { appName: this.siteName, siteLoginUrl: this.siteLoginUrl, siteLogin: this.siteLogin, logoLinkUrl: this.logoLinkUrl, logoLink: this.logoLink}
              )
            )
          ) : (
            renderHTML(
              _t.translate(
                "Interface page for register on <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> betting platform",
                { siteLoginUrl: this.siteLoginUrl, siteLogin: this.siteLogin }
              )
            )
          )}
        </p>
      </div>
    );
  }
}

export default Footer;
