import { Action, Reducer } from "redux";
import { Register } from "src/model/myTypes";
import { InitialStateRegister } from "./initial-state";
import { RegisterActions } from "../actions/action-register";

const unloadedState: Register = InitialStateRegister;

export const reducer: Reducer<Register> = (state: Register | undefined, incomingAction: Action): Register => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as RegisterActions;
  switch (action.type) {
    case "POST_REGISTER_START":
      return {
        ...state,
        registerStartResponse: action.payload.success,
        registerStartMessage: action.payload.message,
      };
    case "IS_USERNAME_FREE":
      return {
        ...state,
        usernameFree: action.payload.isFree,
      };
    case "IS_VALID_HASH": {
      return {
        ...state,
        isValidHash: action.payload.isValid,
        hash: action.payload.hash,
        preData: action.payload.preData || {},
      };
    }
    case "RESET_RESPONSE": {
      return {
        ...state,
        registerStartResponse: false,
        registerStartMessage: "",
      };
    }
    case "POST_REGISTER_CONTINUE": {
      return {
        ...state,
        registerContinueResponse: action.payload.RegisterContinueResponse,
        registerContinueMessage: action.payload.message,
      };
    }
    case "RESET_RESPONSE_SECOND": {
      return {
        ...state,
        registerContinueResponse: false,
        registerContinueMessage: "",
      };
    }
    case "IS_VALID_HASH_PASSWORD": {
      return {
        ...state,
        isValidHashPassword: action.payload.isValid,
      };
    }
    case "POST_REGISTER_NEW_PASSWORD": {
      return {
        ...state,
        passwordRedefineMessage: action.payload.message,
        passwordRedefineSuccess: action.payload.success,
      };
    }
    case "RESET_RESPONSE_PASSWORD": {
      return {
        ...state,
        passwordRedefineMessage: "",
        passwordRedefineSuccess: false,
      };
    }
    case "UPDATE_AGENT_INFO":
      return {
        ...state,
        availableCurrencies: action.payload.currencies,
        requestDocumentsAddress: action.payload.requestDocumentsAddress,
        requestDocumentsIdentity: action.payload.requestDocumentsIdentity,
        requestInformationAddress: action.payload.requestInformationAddress,
        requestInformationIdentity: action.payload.requestInformationIdentity,
        autoApproveUser: action.payload.autoApproveUser,
        phoneNumberResgistration: action.payload.phoneNumberResgistration,
        birthdateResgistration: action.payload.birthdateResgistration,
        cpfResgistration: action.payload.cpfResgistration,
        forceEmailValidation: action.payload.forceEmailValidation,
      };
  }
  return state;
};
