import { Component } from "react";
import LanguageSeletor from "./languageSeletor";
import LazyLoad from "react-lazyload";
import Settings from "src/settings/settings-class";
import { Button } from "@material-ui/core";
import styles from "./header.module.css";

class Header extends Component {
  render() {
    const folderImgs = Settings.data.folderImgs;
    const hasBgImg = !!Settings.data.styles.headerBackgroundImage;
    const logoUrl = Settings.data.logoLinkUrl;

    return (
      <div className={styles.header}
        style={hasBgImg ? ({
          backgroundImage: `url("${Settings.data.styles.headerBackgroundImage}")`,
        }) : undefined}
      >
        <LazyLoad height={72} once >
          {logoUrl !== "" ? (
            <Button
              className={styles.buttonSubmit}
              href={logoUrl}
            >
              <img
                src={`/img/${folderImgs}/logo.svg`}
                alt={"logo"}
                className={styles.logoImg}
              />
            </Button>
          ) : (
            <img
              src={`/img/${folderImgs}/logo.svg`}
              alt={"logo"}
              className={styles.logoImg}
            />
          )}
        </LazyLoad>
        <LanguageSeletor />
      </div>
    );
  }
}

export default Header;
