import "./css/font-sizes.css";
import "./css/main.css";
import "./css/style-variables.css";

import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Settings from "./settings/settings-class";

// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
const rootElement = document.getElementById("root");

const settings = Settings.data; // initialize singleton "SettingsClass"

// Override CSS variables, namely headerBackgroundColor and headerTextColor
settings.styles && Object.entries(settings.styles).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--${key}`, value);
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
