import { SiteSettings } from "./settings-class";

export const settings: SiteSettings = {
  siteName: "PunterPlay",
  domainName: "PunterPlay.com",
  logoLink: "",
  siteLogin: "punterplay.com",
  paymentsApiUrl: "https://register.punterplay.com/api",
  email: "support@punterplay.com",
  folderImgs: "punterplay-assets",
  registerAskVolume: false,
  currenciesList: ["BRL", "EUR", "USD"],
  defaultLang: 'br',
  allowedLanguagesList: {en: 'gb', pt: 'br'},
  styles: {
    headerBackgroundColor: "#222",
    headerTextColor: "#fff"
  }
};
